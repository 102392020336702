import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { Navigation } from '../components/navigation'
import { ThemeContext, Theme } from '../context/ThemeContext'
import LOGO from '../images/svgs/GM-logo.inline.svg'

export const Header = () => {
  const { theme, showNavigation, toggleNavigation } = useContext(ThemeContext)

  const handleClick = () => {
    toggleNavigation()
  }

  return (
    <header className="header">
      <div className="container-theme">
        <div className="container-fluid">
          <div className="row">
            <div className="col align-self-start">
              <div className="logo-container">
                <Link to="/" onClick={() => showNavigation && handleClick()}>
                  <h1>
                    <LOGO
                      className="logo"
                      fill={
                        theme === Theme.DARK && !showNavigation
                          ? 'white'
                          : 'black'
                      }
                    />
                  </h1>
                </Link>
              </div>
            </div>
            <div className="col align-self-end">
              <div
                className={`plus-container ${
                  showNavigation ? 'header-navigation-open' : ''
                }`}
              >
                <button
                  className={`plus ${showNavigation ? 'active' : ''}`}
                  onClick={() => handleClick()}
                  aria-label="Open navigation"
                >
                  <div className="plus-icon"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navigation />
    </header>
  )
}
