import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'

interface IBlockText {
  blocks?: unknown[]
  className?: string
}

const BlockText = ({ blocks, className }: IBlockText) => (
  <BaseBlockContent blocks={blocks} className={className} />
)

export default BlockText
