import React, { useContext, useEffect, ReactNode, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import { ThemeContext } from '../context/ThemeContext'
import { Header } from './header'
import { Footer } from './footer'
import '../styles/index.scss'

interface LayoutProps {
  children: ReactNode
}

const Layout = (props: LayoutProps) => {
  const { children } = props
  const { theme, changeTheme } = useContext(ThemeContext)
  const location = useLocation()
  const layoutRef = useRef(null)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    changeTheme(location.pathname)
    layoutRef?.current?.classList.add('fade-in')
  }, [changeTheme, location.pathname])

  return (
    <div
      ref={layoutRef}
      className={`layout ${theme} ${location.pathname === '/' ? 'index' : ''}`}
    >
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
