import React, { useContext, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { ThemeContext } from '../context/ThemeContext'

export const Navigation = () => {
  const { showNavigation, toggleNavigation } = useContext(ThemeContext)

  const data = useStaticQuery(graphql`
    query {
      sanityCareers {
        hiring
      }
    }
  `)
  const { hiring } = data.sanityCareers

  useEffect(() => {
    if (showNavigation) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('html')[0].style.removeProperty('overflow')
    }
  }, [showNavigation])

  return (
    <nav
      className={`navigation ${showNavigation ? 'active' : ''}`}
      aria-hidden={!showNavigation}
    >
      <div className="container-theme">
        <div className="container-fluid">
          <div className="row">
            <ul
              className="navigation-list"
              onClick={e => {
                e.target.matches('a') &&
                  setTimeout(() => {
                    toggleNavigation()
                  }, 100)
              }}
            >
              <li className="navigation-item">
                <Link to="/projects">Projects</Link>
              </li>
              <li className="navigation-item">
                <Link to="/about">About</Link>
              </li>
              <li className="navigation-item">
                <Link to="/process">Process</Link>
              </li>
              <li className="navigation-item">
                <Link to="/media">Media</Link>
              </li>
              <li className="navigation-item">
                <Link to="/contact">Contact</Link>
              </li>
              {hiring && (
                <li className="navigation-item">
                  <Link to="/careers">Careers</Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}
