import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../context/ThemeContext'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { debounce } from 'lodash'
import BlockText from '../components/block-text'
export const Footer = () => {
  const { showNavigation, toggleNavigation } = useContext(ThemeContext)

  const data = useStaticQuery(graphql`
    query {
      sanitySettings {
        id
        _rawAddress
        phone
        email
      }
    }
  `)
  const { _rawAddress, phone, email } = data.sanitySettings
  const [isIndexMobile, setIsIndexMobile] = useState<boolean>(false)
  const [isIndexMediumUp, setIsIndexMediumUp] = useState<boolean>(false)

  useEffect(() => {
    const isIndex = window.location.pathname === '/'

    const debounceHandleResize = debounce(function handleResize() {
      setIsIndexMobile(isIndex && window.innerWidth <= 767.98)
      setIsIndexMediumUp(isIndex && !showNavigation && window.innerWidth >= 768)
    }, 300)

    if (isIndex && window.innerWidth <= 767.98) setIsIndexMobile(true)
    if (isIndex && window.innerWidth >= 768) setIsIndexMediumUp(true)
    if (showNavigation) setIsIndexMediumUp(false)

    window.addEventListener('resize', debounceHandleResize)

    // Cleanup
    return () => {
      removeEventListener('resize', debounceHandleResize)
    }
  }, [showNavigation])

  return (
    <footer className={`footer ${showNavigation ? 'active' : ''}`}>
      <div className="container-theme">
        <div className="container-fluid">
          <section className="row">
            <BlockText
              className={`address col-8 col-sm-4 col-md-4 col-xl-1 order-sm-1 order-lg-first ${
                isIndexMobile ? 'invisible' : ''
              }`}
              blocks={_rawAddress}
            />
            <div className="contacts col-sm-4 col-md-4 col-xl-2 order-sm-3 order-xl-2">
            <ul onClick={e => {
                if (showNavigation && e.target.matches('a')) {
                  setTimeout(() => {
                    toggleNavigation()
                  }, 100)
                }
              }}>
                <li>
                  <a href={`tel:${phone}`}>{phone}</a>
                </li>
                <li>
                  <a href={`mailto:${email}`}>{email}</a>
                </li>
                <li>
                  <Link to="/contact">Request a call</Link>
                </li>
              </ul>
            </div>
            <div
              className={`terms-careers col-4 offset-4 col-sm-4 offset-sm-0 col-md-4 offset-md-0 col-xl-2 offset-xl-0 order-sm-2 ${
                isIndexMobile ? 'invisible' : ''
              } ${isIndexMediumUp ? 'invisible' : ''}`}
            >
              <ul onClick={e => {
                e.target.matches('a') &&
                  setTimeout(() => {
                    toggleNavigation()
                  }, 100)
              }}>
                <li>
                  <Link
                    to="/terms-and-conditions"
                    className="terms"
                  >
                    Terms &amp; Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
              </ul>
            </div>
            <div
              className={`copyright-credit col-4 offset-4 col-sm-4 offset-sm-0 col-md-4 offset-md-0 col-xl-2 offset-xl-1 order-sm-last ${
                isIndexMobile ? 'invisible' : ''
              } ${isIndexMediumUp ? 'invisible' : ''}`}
            >
              <ul>
                <li>Bld Lic 39086</li>
                <li>{`© ${new Date().getFullYear()}`} GOODMANORS</li>
                <li>
                  Website by{' '}
                  <a href="https://www.ellewilliams.com/" target="_blank">
                    Elle Williams Studio
                  </a>{' '}
                  &amp;{' '}
                  <a href="https://srrycmpny.com/" target="_blank">
                    Sorry Company
                  </a>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </footer>
  )
}
